const getEnvironmentKey = () => {
  if (typeof window !== 'undefined') return window.environment;
  if (typeof process !== 'undefined') return process.env.NODE_ENV;
  return 'development';
};

const environmentKey = getEnvironmentKey();

// Remove or modify console.logs to be safe
const logEnvironment = () => {
  if (typeof window !== 'undefined') {
    console.log('Environment key:', environmentKey);
    console.log('Window environment:', window.environment);
  }
};

logEnvironment();

const environment = {
  development: {
    isProduction: false,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_bioventusdevexogen2_1/0oafjxhjiwcvdmSfm0h7/alnfjxwyutP5W5cio0h7',
    apiPort: '8000',
    apiTimeout: {
      response: 120000, // Wait 5 seconds for the server to start sending,
      deadline: 120000 // but allow 1 minute for the file to finish loading.
    },
    features: {
      document_scanner: true,
      additional_equipment: true,
      shipping_note: true,
      update_delivery_address: true,
      delivery_signature: true,
      shipping_fax_physician: true
    }
  },
  staging: {
    isProduction: true,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_bioventusstagingexogen_1/0oafjxh31mGrNQrlv0h7/alnfjxvykglZ0Kggb0h7',
    apiPort: '8000',
    features: {
      document_scanner: true,
      additional_equipment: true,
      shipping_note: true,
      update_delivery_address: false,
      delivery_signature: true,
      shipping_fax_physician: true
    }
  },
  test: {
    isProduction: true,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_testexogen_1/0oaty0pxsiTnX5HJN0h7/alnty111mfO7MofuF0h7',
    apiPort: '8000',
    features: {
      document_scanner: true,
      additional_equipment: true,
      shipping_note: true,
      update_delivery_address: false,
      delivery_signature: true,
      shipping_fax_physician: true
    }
  },
  training: {
    isProduction: true,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.okta.com',
    oktaRedirect:
      'https://bioventus.okta.com/home/bioventus_exogentrainingportal_1/0oa7h7qrmw7RxH0JC1t7/aln7h7x1tvkrI5Bqw1t7',
    apiPort: '8000',
    features: {
      document_scanner: true,
      additional_equipment: true,
      shipping_note: true,
      update_delivery_address: false,
      delivery_signature: true,
      shipping_fax_physician: true
    }
  },
  production: {
    isProduction: true,
    docusignDetailUrl: 'https://app.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.okta.com',
    oktaRedirect:
      'https://bioventus.okta.com/home/bioventus_exogendirect_1/0oa4aizyaap2yJoeK1t7/aln4aj65hzMni1LqO1t7',
    apiPort: '80',
    features: {
      document_scanner: true,
      additional_equipment: true,
      shipping_note: true,
      update_delivery_address: false,
      delivery_signature: true,
      shipping_fax_physician: true
    }
  }
}[environmentKey || 'development'];

module.exports = Object.assign(
  {
    app: {
      title: 'ExogenDirect',

      description: '',
      head: {
        titleTemplate: '%s',
        meta: [{ charset: 'utf-8' }]
      }
    },
    apiSegment: '/exogen/api',
    apiTimeout: {
      response: 120000,
      deadline: 120000
    },
    notifcationInterval: 5000,
    apiRedirect: '/exogen-direct/app'
  },
  environment
);
