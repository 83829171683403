const getEnvironmentKey = () => {
  if (typeof window !== 'undefined') return window.environment;
  if (typeof process !== 'undefined') return process.env.NODE_ENV;
  return 'development';
};

const environmentKey = getEnvironmentKey();

const environment = {
  development: {
    isProduction: false,
    copilotDemoUrl: 'https://staging-hub.mybv360.com/users/demo_sign_in',
    corevistOkaRedirect:
      'https://bioventus.oktapreview.com/home/oidc_client/0oaq3vh4a9xAPM0Oy0h7/aln5z7uhkbM6y7bMy0g7',

    copilotOkaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_bioventusportalqacopilot_1/0oaq4qh9vjU1P6YEJ0h7/alnq4qt8up30qkmO50h7',
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_developmentbv360_1/0oandba611NyNEVyn0h7/alnndbchyrsUhAhcC0h7',
    apiPort: '8000',
    apiTimeout: {
      response: 60000, // Wait 5 seconds for the server to start sending,
      deadline: 60000 // but allow 1 minute for the file to finish loading.
    },
    features: {
      exogenRx: true
    }
  },
  staging: {
    isProduction: true,
    // copilotDemoUrl: 'https://staging-hub.mybv360.com/users/demo_sign_in',
    copilotDemoUrl:
      'https://bioventus.oktapreview.com/home/bioventus_copilotdemostaging_1/0oarh5tn0fRwvSiWx0h7/alnrh5v2k8wvlOWMU0h7',
    corevistOkaRedirect:
      'https://bioventus.oktapreview.com/home/oidc_client/0oaq3vh4a9xAPM0Oy0h7/aln5z7uhkbM6y7bMy0g7',
    copilotOkaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_bioventusportalqacopilot_1/0oaq4qh9vjU1P6YEJ0h7/alnq4qt8up30qkmO50h7',
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_stagingbv360_1/0oanddnbnjTMElTWe0h7/alnnddrsw9XDAkPTI0h7',
    apiPort: '8000',
    features: {
      exogenRx: true
    }
  },
  test: {
    isProduction: true,
    copilotDemoUrl:
      'https://bioventus.oktapreview.com/home/bioventus_copilotdemostaging_1/0oarh5tn0fRwvSiWx0h7/alnrh5v2k8wvlOWMU0h7',
    corevistOkaRedirect:
      'https://bioventus.oktapreview.com/home/oidc_client/0oaq3vh4a9xAPM0Oy0h7/aln5z7uhkbM6y7bMy0g7',
    copilotOkaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_bioventusportalqacopilot_1/0oaq4qh9vjU1P6YEJ0h7/alnq4qt8up30qkmO50h7',
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/app/bioventus_testmybv360_1/exk1f3e5jkh2FI2po0h8/sso/saml',
    apiPort: '8000',
    features: {
      exogenRx: true
    }
  },
  training: {
    isProduction: true,
    copilotDemoUrl:
      'https://bioventus.okta.com/home/bioventus_copilotdemostaging_1/0oae7b3sdb2xvKmzG1t7/alne7b5ycfKlQHSzw1t7',
    corevistOkaRedirect:
      'https://bioventus.okta.com/home/oidc_client/0oa19rlfetD2sALb41t7/alntwmdyyUB5fs8d50g4',
    copilotOkaRedirect:
      'https://bioventus.okta.com/home/bioventus_mybv360trainingcopilotsaml_1/0oaehr6dhayVWG85U1t7/alnehr7zb2QzrflYK1t7',
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.okta.com',
    oktaRedirect:
      'https://bioventus.okta.com/home/bioventus_trainingmybv360_1/0oae23pktnNpQFXay1t7/alne23r6fhhncvGnK1t7',
    apiPort: '8000',
    features: {
      exogenRx: true
    }
  },
  production: {
    isProduction: true,
    copilotDemoUrl:
      'https://bioventus.okta.com/home/bioventus_copilotdemoproduction_1/0oae7eoaa8LTqYRqO1t7/alne7es1oazSlmkGv1t7',
    corevistOkaRedirect:
      'https://bioventus.okta.com/home/oidc_client/0oa1ygrw1p8p04kIT1t7/alntwmdyyUB5fs8d50g4',
    copilotOkaRedirect:
      'https://bioventus.okta.com/home/bioventus_mybv360_1/0oa9migh0aRBurSDC1t7/aln9miippenpgnXgs1t7',
    docusignDetailUrl: 'https://app.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.okta.com',
    oktaRedirect:
      'https://bioventus.okta.com/home/bioventus_mybv360production_1/0oae7i2b4wSXvsAUR1t7/alne7i46vwoQttXFV1t7',
    apiPort: '80',
    features: {
      exogenRx: false
    }
  }
}[environmentKey || 'development'];

module.exports = Object.assign(
  {
    app: {
      title: 'MyBV360',

      description: '',
      head: {
        titleTemplate: '%s',
        meta: [{ charset: 'utf-8' }]
      }
    },
    bv360SupportEmail: 'admin.online@bioventus.com',
    apiSegment: '',
    apiTimeout: {
      response: 60000,
      deadline: 60000
    },
    recaptchaSiteKeyV3: '6LfSLroUAAAAAMJ39fOVJKRihPnm8_llfjiObxCx'
  },
  environment
);
